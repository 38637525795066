<template>
    <div>
        <H2 style="text-align: center">欢迎来到{{ tenantName }}系统</H2>
        <BindingPersonalPhone :dialogFormVisible="dialogFormVisible" :userName="userName"
                              @closeBindingPersonalPhoneDialog="closeBindingPersonalPhoneDialog"/>
    </div>
</template>
<script>
import BindingPersonalPhone from "@/components/BindingPersonalPhone.vue";
import {getAccountName} from "@/utils/auth";
import {index} from "@/utils/api";

export default {
    name: "Welcome",
    components: {BindingPersonalPhone},
    props: {},
    data() {
        return {
            dialogFormVisible: true,
            tenantName: ''
        }
    },
    computed: {
        userName() {
            return getAccountName();
        }
    },
    methods: {
        closeBindingPersonalPhoneDialog() {
            this.dialogFormVisible = true
        },
        initLogo() {
            index().then(res => {
                if (res.success) {
                    const resEntity = res.data
                    this.tenantName = resEntity.title
                }
            })
        }
    },
    created() {
        this.initLogo();
    },
    mounted() {
        try {
            this.dialogFormVisible = JSON.parse(this.$route.query.binding)
        } catch (e) {
            this.dialogFormVisible = true;
        }
    }
}
</script>
<style scoped>

</style>